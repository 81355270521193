import { map } from 'lodash'

export enum MESSAGE_TYPE {
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS'
}

export enum LEGAL_FORM {
	CITIZEN = 'CITIZEN',
	COMPANY = 'COMPANY'
}

export enum MAP_DISTRICT {
	STARE_MESTO = 'STARE_MESTO',
	RUZINOV = 'RUZINOV',
	VRAKUNA = 'VRAKUNA',
	PODUNAJSKE_BISKUPICE = 'PODUNAJSKE_BISKUPICE',
	NOVE_MESTO = 'NOVE_MESTO',
	RACA = 'RACA',
	VAJNORY = 'VAJNORY',
	KARLOVA_VES = 'KARLOVA_VES',
	DUBRAVKA = 'DUBRAVKA',
	LAMAC = 'LAMAC',
	DEVIN = 'DEVIN',
	DEVINSKA_NOVA_VES = 'DEVINSKA_NOVA_VES',
	ZAHORSKA_BYSTRICA = 'ZAHORSKA_BYSTRICA',
	PETRZALKA = 'PETRZALKA',
	JAROVCE = 'JAROVCE',
	RUSOVCE = 'RUSOVCE',
	CUNOVO = 'CUNOVO'
}

export enum SETTINGS_TYPE {
	TREES_GOAL = 'TREES_GOAL',
	TREES_PROGRESS = 'TREES_PROGRESS'
}

export enum MAP_PIN_TYPE {
	STROM = 'STROM',
	STROM_NADOBA = 'STROM_NADOBA',
	KRIK = 'KRIK',
	KRIK_NADOBA = 'KRIK_NADOBA',
	NAHRADNA_VYSADBA = 'NAHRADNA_VYSADBA',
	VYRUB_INVAZNYCH_DREVIN = 'VYRUB_INVAZNYCH_DREVIN',
	INJEKTAZ_INVAZNYCH_DREVIN = 'INJEKTAZ_INVAZNYCH_DREVIN',
	HAVARIJNY_VYRUB = 'HAVARIJNY_VYRUB',
	ODSTRANENIE_PADNUTEHO_STROMY = 'ODSTRANENIE_PADNUTEHO_STROMY',
	VYSADBA = 'VYSADBA',
	VYSADBA_2021 = 'VYSADBA_2021',
	VYRUB = 'VYRUB',
	//ODVOZ_BIOODPADU = 'ODVOZ_BIOODPADU',
	OREZ_STROMOV = 'OREZ_STROMOV',
	OREZ_KROV = 'OREZ_KROV',
	ODSTRANENIE_PNA = 'ODSTRANENIE_PNA',
	CISTENIE = 'CISTENIE',
	DENDROLOGICKY_POSUDOK = 'DENDROLOGICKY_POSUDOK'
}

export const districts = {
	STARE_MESTO: 'Staré Mesto',
	RUZINOV: 'Ružinov',
	VRAKUNA: 'Vrakuňa',
	PODUNAJSKE_BISKUPICE: 'Podunajské Biskupice',
	NOVE_MESTO: 'Nové mesto',
	RACA: 'Rača',
	VAJNORY: 'Vajnory',
	KARLOVA_VES: 'Karlova Ves',
	DUBRAVKA: 'Dúbravka',
	LAMAC: 'Lamač',
	DEVIN: 'Devín',
	DEVINSKA_NOVA_VES: 'Devínska Nová Ves',
	ZAHORSKA_BYSTRICA: 'Záhorská Bystrica',
	PETRZALKA: 'Petržalka',
	JAROVCE: 'Jarovce',
	RUSOVCE: 'Rusovce',
	CUNOVO: 'Čunovo',
}

export enum BUTTON_ACTION {
	MODAL = 'MODAL',
	LINK = 'LINK'
}

export const districtOptions = map(MAP_DISTRICT, (value) => ({ value, label: districts[value] }))

