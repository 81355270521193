import React from "react"
import { get } from "lodash"

import Head from "next/head"

const image = require("../assets/images/backgrounds/seo-image.png")

type Props = {
	pageMetadata: object
}

const SEOHelmet = ({ pageMetadata }: Props) => {
	return (
		<Head>
			<meta charSet="utf-8" />
			<link rel="canonical" href={get(pageMetadata, "url")} />

			<title>
				{get(
					pageMetadata,
					"title",
					"Spravme Bratislavu zelenšou a príjemnejšou pre život"
				)}
			</title>
			<meta
				name={"description"}
				content={get(pageMetadata, "description")}
			/>
			<meta
				name={"keywords"}
				content={get(
					pageMetadata,
					"keywords",
					"Bratislava, Bratislavskí susedia, Eko, 10 000 stromov, komunitné záhrady"
				)}
			/>

			<meta property={"og:url"} content={get(pageMetadata, "ogUrl")} />
			<meta
				property={"og:title"}
				content={get(pageMetadata, "ogTitle")}
			/>
			<meta
				property={"og:description"}
				content={get(pageMetadata, "ogDescription")}
			/>
			<meta
				property={"og:image"}
				content={get(pageMetadata, "ogImage") || image}
			/>
			<meta property={"og:locale"} content={"sk_SK"} />

			<meta
				property={"og:type"}
				content={get(pageMetadata, "type") || "article"}
			/>
		</Head>
	)
}

export default SEOHelmet
