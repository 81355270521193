enum MENU_ITEM {
	ABOUT = 'ABOUT',
	INFO = 'INFO',
	PROJECT = 'PROJECT',
	TOPICS = 'TOPICS',
	BLOG = 'BLOG',
	MAP = 'MAP',
	CONTACT = 'CONTACT',
	PARTNERS = 'PARTNERS'
}

export const MENU_ITEMS = Object.values(MENU_ITEM)

export default MENU_ITEM
